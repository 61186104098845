import type { ISharedIconSocial } from '~/components/SharedGroupIcons/SharedGroupIcons.types';

export const newDefaultIcons: ISharedIconSocial[] = [
  {
    componentName: 'NewGoogleIcon',
    eventName: 'google',
  },
  {
    componentName: 'NewSteamIcon',
    eventName: 'steam',
  },
  {
    componentName: 'NewVkIcon',
    eventName: 'vk',
  },
];
